<template lang="html">
    <div class="Split">
        <div class="Split--Remove" v-show="countSelected > 0" v-on:click="removeAndUpdate()">
            <button>
                {{$t('watmonitor_restart_split')}}
                <i class="far fa-trash-alt"></i>
            </button>
        </div>
        <div class="Split--Content" v-show="!hideDashboardSocioDemographics">
            <h1>{{$t('watmonitor_sociodemographic_data')}}</h1>
            <SelectableItem :selected="globalFilters[0].split" v-on:click="update(globalFilters[0])" :text="$t('watmonitor_gender')"/>
            <SelectableItem :selected="globalFilters[1].split" v-on:click="update(globalFilters[1])" :text="$t('watmonitor_age')"/>
            <!--<SelectableItem :selected="globalFilters[2].split" v-on:click="update(globalFilters[2])" :text="'Provincias'"/>
            <SelectableItem :selected="globalDemographicFilters[0].split" v-on:click="update(globalDemographicFilters[0])" :text="'Comunidades Autónomas'"/>-->
            <SelectableItem :selected="globalDemographicFilters[1].split" v-on:click="update(globalDemographicFilters[1])" :text="$t('watmonitor_geographic')"/>
        </div>
        <div class="Split--Content">
            <h1>{{$t('watmonitor_answers')}}</h1>
            <SelectableItem  v-for="(f, index) in globalQuestionFilters" :selected="f.split" v-on:click="update(f)" :text="index + '. ' + f.questionText"/>
        </div>
    </div>
  </template>
  
  <script>
import SelectableItem from './SelectableItem.vue'

  export default {
    name: 'Split',
    components:{
        SelectableItem
    },
    props: {
        globalDemographicFilters: Array,
        globalQuestionFilters: Array,
        globalFilters: Array,
        hideDashboardSocioDemographics:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        countSelected(){
            for(let f of this.globalFilters){
                if(f.split) return 1
            }
            for(let f of this.globalQuestionFilters){
                if(f.split) return 1
            }
            if(this.globalDemographicFilters[0].split) return 1;
            if(this.globalDemographicFilters[1].split) return 1;
            return 0
        }
    },
    methods:{
        update(filter){
            filter.split = !filter.split
            if(!filter.split){
                this.$emit('update')
                return
            }
            for(let f of this.globalFilters){
                if(f.taskNum != filter.taskNum || f.questionNum != filter.questionNum || filter.questionFakeName != undefined) f.split = false
            }
            for(let f of this.globalQuestionFilters){
                if(f.taskNum != filter.taskNum || f.questionNum != filter.questionNum || f.questionId != filter.questionId) f.split = false
            }
            if(filter.questionFakeName != 'REGION') this.globalDemographicFilters[0].split = false;
            if(filter.questionFakeName != 'NIELSEN') this.globalDemographicFilters[1].split = false;
            
            this.$emit('update')
        },
        resetSplit(filters){
            filters.forEach(filter => filter.split = false);
        },
        removeAndUpdate() {
            this.resetSplit(this.globalFilters);
            this.resetSplit(this.globalQuestionFilters);
            this.globalDemographicFilters.forEach(filter => filter.split = false);
            this.$emit('update');
        }
    }
  }
  </script>
  <style lang="scss" scoped>
  h1{
    font-size: 12px;
    font-weight: 400;
    color: #626262;
    text-transform: uppercase;
    padding: 12px 13px;
  }
  .Split{
    font-family: Lato;
    &--Content{
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        flex-direction: column;
        gap: 4px;
        text-align: justify;
        padding: 10px;
    }
    &--Remove{
        padding: 16px 24px;
        button{
            border: 1px solid #c8c8c8;
            width: 100%;
            padding: 6px 0;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-weight: bold;
            background: transparent;
        }
    }
  }
  </style>