<template lang="html">
    <div class="Values">
        <div class="Values--ANDOR" v-show="showCombination">
            <label class="radio" :class="{'selected':cl.value == filter.combination}" v-for="cl in combinationList">
                {{cl.value}}
                <input type="radio" v-on:change="$emit('update')" :value="cl.value" name="combination" v-model="filter.combination">
                <span></span>
            </label>
        </div>
        <div class="Values--Container">
            <div class="Values--Value" v-for="(v, index) in getValues">
                <label class="checkbox">
                    {{ v.value  || v.groupName || 'Imagen ' + (index + 1)}}
                    <input type="checkbox" v-model="v.selected" v-on:change="$emit('update')">
                    <span></span>
                </label>
                <div>
                    {{ getTotals(index) }}
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Filters',
    props: {
        filter: Object,
        showCombination: {
            type: Boolean,
            default: false
        },
        totals: Array,
        isGroup: {
            type: Boolean,
            default: false
        }
    },
    computed:{
        combinationList(){
            return [
                {value:'OR'},
                {value:'AND'}
            ]
        },
        getValues(){
            return this.isGroup ? this.filter.groups : this.filter.values 
        }
    },
    methods:{
        getTotals(index){
            if(this.totals[index] == undefined) return ""
            return this.totals[index].number + " | " + this.totals[index].percent + "%"
        }
    }
  }
  </script>
  <style lang="scss" scoped>
    .Values{
        display: flex;
        flex-direction: column;
        gap: 16px;
        &--Container{
            max-height: 300px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 16px;
            &::-webkit-scrollbar-track{
                background-color: #e6e6e6;
            }
            &::-webkit-scrollbar {
                background: #8134ff;
                width: 3px;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 20px;
                background: #8134ff;
            }
        }
        &--Value{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            gap:14px;
            padding-right: 5px;
            div{
                white-space: nowrap;
            }
        }
        &--ANDOR{
            display: flex;
            gap: 12px;
        }
    }
    .radio{
        padding:6px 8px 6px 32px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid #C8C8C8;
        &.selected{
            border-color: #8134FF;
            background: #F6F0FF;
        }
        &:hover input ~ span{
            background-color: #ccc;
        }
        &.selected span {
            background: #8134FF;
            border:1px solid #8134FF;
        }
        &.selected span:after {
            display: block;
        }
        span{
            position: absolute;
            top: 6px;
            left: 4px;
            height: 16px;
            width: 16px;
            background-color: white;
            border: 1px solid #C8C8C8;
            border-radius: 50%;
            &:after{
                content: "";
                position: absolute;
                display: none;
                left: 4px;
                top: 4px;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: white;
            }
        }
        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
    .checkbox{
        padding-left: 23px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        text-align: justify;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:hover input ~ span{
            background-color: #ccc;
        }
        input:checked ~ span {
            background: #8134FF;
            border:1px solid #8134FF;
        }
        input:checked ~ span:after {
            display: block;
        }
        span{
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: white;
            border: 1px solid #C8C8C8;
            border-radius: 4px;
            transition: 0.5s;
            &:after{
                content: "";
                position: absolute;
                display: none;
                left: 5px;
                top: 2px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
  </style>