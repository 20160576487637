<template lang="html">
    <div class="Filters" :class="{'showSaveFilter':saveFilterActive}">
        <div class="Filters--Remove" v-show="saveFilterActive" v-on:click="removeFiltersAndUpdate()">
            <button>
                {{$t('watmonitor_restart_filters')}}
                <i class="far fa-trash-alt"></i>
            </button>
        </div>
        <CollapsableItem class="Filters--Variables" :text="$t('watmonitor_filter_variables')" :count="countSelectedNum(filterVariables)" v-if="filterVariables.length > 0">
            <SelectableItem v-for="f in filterVariables" :text="f.name" :selected="f.selected" showTrash v-on:click="updateFilterWithVariable(f)" v-on:remove="removeVariable(f)" />
        </CollapsableItem>
        <CollapsableItem :num="1" :text="$t('watmonitor_gender')" :count="countSelectedNum(globalFilters[0].values)" v-if="!hideDashboardSocioDemographics">
            <Values :totals="getQuestionYtotals(globalFilters[0].questionNum, globalFilters[0].taskNum)" :filter="globalFilters[0]" :showCombination="false" v-on:update="updateFilter()"/>
        </CollapsableItem>
        <CollapsableItem :num="2" :text="$t('watmonitor_age')" v-if="!hideDashboardSocioDemographics">
            <AgeRanges :filter="globalFilters[1]" v-on:update="updateFilter()"/>
        </CollapsableItem>
        <CollapsableItem :num="3" :text="$t('watmonitor_geographics')"  v-if="!hideDashboardSocioDemographics">
            <ButtonTabs :selected="selectedGeoFilter" :tabs="[{text:'Provincia', id:'PROVINCE'},{text:'Comunidad autónoma', id:'REGION'},{text:'Zonas geográficas', id:'NIELSEN'}]" v-on:select="selectGeoFilter($event)"/>
            <div>
                <Values v-show="selectedGeoFilter == 0" :totals="getQuestionYtotals(globalFilters[2].questionId, globalFilters[2].taskNum)" :filter="globalFilters[2]" v-on:update="updateGeoFilter('PROVINCE')"/>
                <Values v-show="selectedGeoFilter == 1" :totals="getQuestionYtotals(globalDemographicFilters[0].questionId, globalDemographicFilters[0].taskNum)" :filter="globalDemographicFilters[0]" isGroup v-on:update="updateGeoFilter('REGION')"/>
                <Values v-show="selectedGeoFilter == 2" :totals="getQuestionYtotals(globalDemographicFilters[1].questionId, globalDemographicFilters[1].taskNum)" :filter="globalDemographicFilters[1]" isGroup v-on:update="updateGeoFilter('NIELSEN')"/>
            </div>
        </CollapsableItem>
        <CollapsableItem class="Filters--Questions" :num="4" :text="$t(('watmonitor_filter_by_answer'))">
            <CollapsableItem v-for="(f, index) in globalQuestionFilters" :num="index" :text="f.questionText" :count="countSelectedNum(f.values)">
                <Values v-if="isQuestionWithValues(f)" :totals="getQuestionYtotals(f.questionId, f.taskNum)" :filter="f" :showCombination="f.questionType == 'MULTISELECTION' || f.questionType == 'MULTIPLEIMAGE_CHOICE'" v-on:update="updateFilter()"/>
                <RankingValues v-if="isRankingQuestion(f)" :filter="f" v-on:update="updateFilter()"/>
            </CollapsableItem>
        </CollapsableItem>
        <div class="Filters--Save" v-show="saveFilterActive">
            <button v-on:click="$emit('save-filter')">
                <i class="far fa-save"></i>
                {{$t('watmonitor_save_filters')}}
            </button>
        </div>
      </div>
  </template>
  
  <script>
  import CollapsableItem from './CollapsableItem.vue'
  import Values from './Values.vue'
  import RankingValues from './RankingValues.vue'
  import AgeRanges from './AgeRanges.vue'
  import SelectableItem from './SelectableItem.vue'
  import ButtonTabs from '../ButtonTabs.vue'
  import { isQuestionWithValues, isRankingQuestion } from '../../../utils/watmonitor/index'
  export default {
    name: 'Filters',
    components:{
        CollapsableItem,
        Values,
        AgeRanges,
        SelectableItem,
        ButtonTabs,
        RankingValues
    },
    props: {
        globalFilters: Array,
        globalQuestionFilters: Array,
        globalDemographicFilters: Array,
        questionsByTask: Array,
        filterVariables: Array,
        hideDashboardSocioDemographics:{
            type: Boolean,
            default: false
        },
        showSaveFilter: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            selectedGeoFilter: 0
        }
    },
    computed:{
        saveFilterActive(){
            return this.showSaveFilter && this.countSelected > 0 && this.countSelectedNum(this.filterVariables) == 0
        },
        countSelected(){
            let count = 0;
            count += this.countSelectedNum(this.globalFilters[0].values);
            count += this.countSelectedNum(this.globalFilters[2].values);
            count += this.countSelectedNum(this.globalDemographicFilters[0].groups);
            count += this.countSelectedNum(this.globalDemographicFilters[1].groups);
            count += this.countSelectedNum(this.filterVariables)
            for (let filter of this.globalQuestionFilters) {
                count += this.countSelectedNum(filter.values);
            }

            return count;
        }
    },
    methods:{
        isQuestionWithValues(question){
            return isQuestionWithValues(question)
        },
        isRankingQuestion(question){
            return isRankingQuestion(question)
        },
        getQuestionYtotals(questionId, taskNum) {
            const task = this.questionsByTask.find(task => task.taskNum == taskNum);
            if (task) {
                const question = task.questions.find(q => q.questionId == questionId);
                if (question && question.chart) {
                    return question.chart.rowTotals || [];
                }
            }
            return [];
        },
        updateFilterWithVariable(f){
           const selected = f.selected
            this.removeFilters()
            if(!selected) this.setFilters(f)
            f.selected = !selected
            this.$emit('updated-filter')
        },
        removeVariable(f){
            this.$emit('remove-variable',f)
            if(f.selected) this.updateFilterWithVariable
        },
        updateFilter(){
            this.removeFilterVariable()
            this.$emit('updated-filter')
        },
        countSelectedNum(values){
            return values.filter(v => v.selected).length
        },
        selectGeoFilter(type){
            if(type == "PROVINCE"){
                this.selectedGeoFilter = 0
            }
            else if(type == "REGION"){
                this.selectedGeoFilter = 1
            }
            else if(type == "NIELSEN"){
                this.selectedGeoFilter = 2
            }
        },
        updateGeoFilter(type){
            this.removeFilterVariable()
            switch (type) {
                case "PROVINCE":
                    this.globalDemographicFilters.forEach(filter => filter.groups = this.deselectValues(filter.groups));
                    break;
                case "REGION":
                    this.globalFilters[2].values = this.deselectValues(this.globalFilters[2].values);
                    this.globalDemographicFilters[1].groups = this.deselectValues(this.globalDemographicFilters[1].groups);
                    break;
                case "NIELSEN":
                    this.globalFilters[2].values = this.deselectValues(this.globalFilters[2].values);
                    this.globalDemographicFilters[0].groups = this.deselectValues(this.globalDemographicFilters[0].groups);
                    break;
                default:
                    break;
            }
            this.updateFilter();
        },
        deselectValues(values){
            return values.map(v => ({ ...v, selected: false }));
        },
        removeFiltersAndUpdate(){
            this.removeFilters()
            this.$emit('updated-filter')
        },
        removeFilterVariable(){
            for(let f of this.filterVariables){
                f.selected = false
            }
        },
        setFilters(filterVariable){
            for(let xFilter of filterVariable.xFilters) {
                if(xFilter.taskNum > 0){
                    this.applyFilter(this.globalQuestionFilters, xFilter, false)
                }
                else if(xFilter.groups && xFilter.groups.length > 0){
                    this.applyFilter(this.globalDemographicFilters, xFilter, true)
                }
                else{
                    this.applyFilter(this.globalFilters, xFilter, false)
                }
            }
        },
        applyFilter(filters, xFilter, hasGroups){
            for(let f of filters){
                if(f.taskNum == xFilter.taskNum && (f.questionNum == xFilter.questionNum || f.questionId == xFilter.questionNum)){
                    if(hasGroups){
                        for(let g of f.groups){
                            for(let filterGroup of xFilter.groups){
                                if(filterGroup.groupName == g.groupName) g.selected = true
                            }
                        }
                    }
                    else if(f.values){
                        for(let v of f.values){
                            if(xFilter.values.includes(v.id)) v.selected = true
                        }
                    }
                    else if(xFilter.ranges) f.ranges = xFilter.ranges
                }
            }
        },
        removeFilters(){
            this.globalDemographicFilters.forEach(filter => filter.groups = this.deselectValues(filter.groups));
            this.globalQuestionFilters.forEach(filter => filter.values = this.deselectValues(filter.values));
            this.globalFilters[0].values = this.deselectValues(this.globalFilters[0].values);
            this.globalFilters[1].ranges = [{ from: 16, to: 100 }];
            this.globalFilters[2].values = this.deselectValues(this.globalFilters[2].values);
            for(let f of this.filterVariables){
                f.selected = false
            }
        }
    }
  }
  </script>
  <style lang="scss">
  .GeographicButtons{
    display: flex;
    justify-content: space-between;
    font-family: Lato;
    padding-bottom: 8px;
    button{
        border: 1px solid #c8c8c8;
        padding: 6px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 6px;
        background: white;
        cursor: pointer;
        &.selected{
            background: #F6F0FF;
            border: 1px solid #8134FF
        }
    }
  }
  .Filters{
    position: relative;
    &.showSaveFilter{
        padding-bottom: 54px;
    }
    &--Questions{
        > .CollapsableItem--Content{
            padding: 16px 0;
            h1{
                font-size: 14px;
            }
        }
    }
    &--Variables{
        > .CollapsableItem--Content{
            padding: 0 10px 16px 10px;
        }
    }
    &--Remove{
        padding: 16px 24px;
        button{
            border: 1px solid #c8c8c8;
            width: 100%;
            padding: 6px 0;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-weight: bold;
            background: transparent;
        }

    }
    &--Save{
        position: fixed;
        bottom:0;
        left:0;
        width: 350px;
        height: 54px;
        background: white;
        padding: 16px 24px;
        transform: translateX(347px);
        button{
            background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
            color: white;
            border: none;
            width: 100%;
            padding: 6px 0;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }
  }
  </style>