<template lang="html">
    <transition name="modal" v-if="show">
        <div class="ModalInput">
          <div class="ModalInput--Element">
            <h1>{{ title }}</h1>
            <div>
              <slot></slot>
            </div>
            <div class="ModalInput--Actions">
              <button v-on:click="$emit('cancel')">
                {{ cancelButtonText }}
              </button>
              <button v-on:click="$emit('confirm')" :disabled="disableConfirm">
                {{ confirmButtonText }}
              </button>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'ModalInput',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    cancelButtonText:{
      type:String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: ''
    },
    disableConfirm: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.ModalInput {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .6);
    display: table;
    transition: opacity .3s ease;
    &--Element{
      padding: 40px;
      gap: 30px;
      background: white;
      position: absolute;
      border-radius: 20px;
      top: 30%;
      width: 450px;
      left: calc(50% - 225px);
      display: flex;
      flex-direction: column;
    }
    &--Actions{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
}
h1{
    font-family: Bariol Bold;
    font-size: 24px;
    font-weight: 700;
    text-align: justify;
}
button{
  font-family: Lato;
  font-size: 15px;
  padding:10px 20px;
  font-weight: 600;
  background: white;
  border-radius: 4px;
  border: 1px solid #EAEAEA;
  cursor: pointer;
  &:disabled{
    opacity: 0.5;
  }
  &:nth-child(2){
    background: #8134FF;
    color: white;
  }
}
</style>
