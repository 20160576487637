<template >
  <div class="WatUxQuantiInfoBox">
    <div>
      <svg v-if="icon == 'time'" width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.4426 11.3591L35.5727 9.22895L30.5754 4.27272L28.4452 6.40286C30.4114 7.71354 32.0908 9.39294 33.4426 11.3591ZM13.9445 3.78125C15.0916 3.53532 16.2386 3.41251 17.4673 3.41251C18.6552 3.41251 19.8431 3.53532 20.9901 3.78125V2.51138H21.9732L21.9735 0.299286H12.9204V2.47024H13.9036L13.9445 3.78125ZM17.4677 5.46064C8.08691 5.46064 0.468018 13.0797 0.468018 22.4603C0.467694 31.841 8.04585 39.4599 17.4677 39.4599C26.8895 39.4599 34.4673 31.8408 34.4673 22.4603C34.4261 13.0795 26.8482 5.46064 17.4677 5.46064ZM17.4677 22.5009V9.6388C24.5952 9.6388 30.3298 15.4145 30.3298 22.5009H17.4677Z" fill="url(#paint0_linear_1276_1042)"/>
        <defs>
        <linearGradient id="paint0_linear_1276_1042" x1="0.468018" y1="19.8796" x2="35.5727" y2="19.8796" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF1564"/>
        <stop offset="1" stop-color="#FD7328"/>
        </linearGradient>
        </defs>
      </svg>
      <svg v-if="icon == 'click'" width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8791 0.450165C14.3819 0.450165 13.9792 0.853204 13.9792 1.35008V9.45008C13.9792 9.94729 14.3822 10.35 14.8791 10.35C15.3763 10.35 15.779 9.94696 15.779 9.45008V1.35008C15.779 0.852875 15.376 0.450165 14.8791 0.450165ZM5.65412 4.27533C5.42372 4.27533 5.18296 4.36667 5.00718 4.54245C4.65562 4.89401 4.65562 5.45651 5.00718 5.80807L10.098 10.8989C10.4495 11.2504 11.0262 11.2504 11.3777 10.8989C11.729 10.5473 11.729 9.9848 11.3777 9.63323L6.28695 4.54245C6.11117 4.36667 5.88452 4.27533 5.65412 4.27533ZM24.1041 4.27533C23.8737 4.27533 23.6471 4.36667 23.4713 4.54245L18.3805 9.63323C18.0293 9.9848 18.0293 10.5473 18.3805 10.8989C18.7321 11.2504 19.2946 11.2504 19.6461 10.8989L24.7366 5.80807C25.0882 5.45651 25.0882 4.89401 24.7366 4.54245C24.5608 4.36667 24.3342 4.27533 24.1038 4.27533H24.1041ZM13.7541 13.2753L21.3899 34.2702L24.5257 27.8577L32.2039 35.55L36.029 31.7248L28.3367 24.0466L34.7493 20.9109L13.7544 13.2748L13.7541 13.2753ZM1.82912 13.5004C1.33191 13.5004 0.929199 13.9034 0.929199 14.4003C0.929199 14.8975 1.33224 15.3002 1.82912 15.3002H9.92912C10.4263 15.3002 10.829 14.8972 10.829 14.4003C10.829 13.9031 10.426 13.5004 9.92912 13.5004H1.82912ZM10.7447 17.6347C10.5143 17.6347 10.2736 17.726 10.0978 17.9018L5.00702 22.9926C4.65546 23.3442 4.65546 23.9067 5.00702 24.2582C5.35827 24.6098 5.93522 24.6098 6.28679 24.2582L11.3776 19.1674C11.7288 18.8159 11.7288 18.2534 11.3776 17.9018C11.2018 17.726 10.9751 17.6347 10.7447 17.6347Z" fill="url(#paint0_linear_1276_1049)"/>
        <defs>
        <linearGradient id="paint0_linear_1276_1049" x1="0.929199" y1="18.0001" x2="36.029" y2="18.0001" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF1564"/>
        <stop offset="1" stop-color="#FD7328"/>
        </linearGradient>
        </defs>
      </svg>
      <svg v-if="icon == 'views'" width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0021 8.28192C11.152 8.50287 11.3967 8.6211 11.6491 8.6211C11.8069 8.6211 11.9648 8.57388 12.0989 8.47909C12.4539 8.23436 12.5487 7.74522 12.2961 7.38222L9.24236 2.94732C8.99763 2.58434 8.50057 2.49748 8.1455 2.74221C7.78252 2.98694 7.69567 3.484 7.9404 3.83908L11.0021 8.28192Z" fill="url(#paint0_linear_1276_1055)"/>
        <path d="M19.7453 7.19292C20.1793 7.19292 20.5343 6.83787 20.5343 6.4039V1.21929C20.5343 0.78531 20.1793 0.430267 19.7453 0.430267C19.3113 0.430267 18.9563 0.78531 18.9563 1.21929V6.4039C18.9563 6.83787 19.3113 7.19292 19.7453 7.19292Z" fill="url(#paint1_linear_1276_1055)"/>
        <path d="M27.8419 8.62098C28.0946 8.62098 28.339 8.50274 28.4889 8.28179L31.5509 3.83894C31.7956 3.48389 31.7088 2.9865 31.3458 2.74207C30.9904 2.49701 30.4934 2.58387 30.2486 2.94684L27.1946 7.38174C26.9419 7.74472 27.0367 8.23384 27.3917 8.47861C27.5261 8.5734 27.684 8.62098 27.8419 8.62098Z" fill="url(#paint2_linear_1276_1055)"/>
        <path d="M22.9016 20.9019C22.9016 22.6454 21.4883 24.0586 19.7448 24.0586C18.0016 24.0586 16.5884 22.6454 16.5884 20.9019C16.5884 19.1587 18.0016 17.7454 19.7448 17.7454C21.4883 17.7454 22.9016 19.1587 22.9016 20.9019Z" fill="url(#paint3_linear_1276_1055)"/>
        <path d="M19.7454 10.6882C9.05322 10.6882 0.577083 19.976 0.221973 20.3709C-0.0541341 20.6708 -0.0541341 21.1285 0.221973 21.4284C0.577015 21.8228 9.05238 31.1111 19.7454 31.1111C30.4309 31.1111 38.9138 21.8233 39.2689 21.4284C39.545 21.1285 39.545 20.6708 39.2689 20.3709C38.9138 19.9762 30.4309 10.6882 19.7454 10.6882ZM19.7451 25.6358C17.1343 25.6358 15.0103 23.5118 15.0103 20.901C15.0103 18.2902 17.1343 16.1662 19.7451 16.1662C22.3559 16.1662 24.4799 18.2902 24.4799 20.901C24.4799 23.5122 22.3562 25.6358 19.7451 25.6358Z" fill="url(#paint4_linear_1276_1055)"/>
        <defs>
        <linearGradient id="paint0_linear_1276_1055" x1="7.802" y1="5.61246" x2="12.4384" y2="5.61246" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF1564"/>
        <stop offset="1" stop-color="#FD7328"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1276_1055" x1="18.9563" y1="3.81159" x2="20.5343" y2="3.81159" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF1564"/>
        <stop offset="1" stop-color="#FD7328"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1276_1055" x1="27.0522" y1="5.6122" x2="31.6893" y2="5.6122" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF1564"/>
        <stop offset="1" stop-color="#FD7328"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1276_1055" x1="16.5884" y1="20.902" x2="22.9016" y2="20.902" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF1564"/>
        <stop offset="1" stop-color="#FD7328"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1276_1055" x1="0.0148927" y1="20.8997" x2="39.4759" y2="20.8997" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF1564"/>
        <stop offset="1" stop-color="#FD7328"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
    <div>
      <h1>{{title}}</h1>
      <p v-html="text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WatUxQuantiInfoBox',
  props:{
    title: String,
    text: String,
    icon: String
  }
}
</script>

<style>
.WatUxQuantiInfoBox span{
  background: -webkit-linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
</style>
<style lang="scss" scoped>
.WatUxQuantiInfoBox{
  background: #FFFFFF;
  padding: 24px;
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
h1{
  font-family: 'Bariol Bold';
  font-weight: 700;
  font-size: 18px!important;
  color: #000000;
  margin: 0!important;
}
p{
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  color: #A6A6A6;
}
</style>
