<template lang="html">
    <label :class="{'selected':selected}">
        <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 1.34001C16.5083 2.21087 17.7629 3.46054 18.6398 4.96531C19.5167 6.47009 19.9854 8.17779 19.9994 9.91935C20.0135 11.6609 19.5725 13.376 18.72 14.8947C17.8676 16.4135 16.6332 17.6832 15.1392 18.5783C13.6452 19.4734 11.9434 19.9628 10.2021 19.9981C8.46083 20.0333 6.74055 19.6132 5.21155 18.7793C3.68256 17.9453 2.39787 16.7265 1.48467 15.2435C0.571462 13.7605 0.0614093 12.0647 0.00500011 10.324L0 10L0.00500011 9.67601C0.0610032 7.94901 0.563548 6.26598 1.46364 4.79101C2.36373 3.31604 3.63065 2.09947 5.14089 1.2599C6.65113 0.420327 8.35315 -0.0135887 10.081 0.000452316C11.8089 0.0144933 13.5036 0.476012 15 1.34001ZM13.707 7.29302C13.5348 7.12084 13.3057 7.01741 13.0627 7.00213C12.8197 6.98685 12.5794 7.06076 12.387 7.21001L12.293 7.29302L9 10.585L7.707 9.29301L7.613 9.21001C7.42058 9.06087 7.18037 8.98704 6.9374 9.00237C6.69444 9.0177 6.46541 9.12113 6.29326 9.29328C6.12112 9.46542 6.01768 9.69445 6.00235 9.93742C5.98702 10.1804 6.06086 10.4206 6.21 10.613L6.293 10.707L8.293 12.707L8.387 12.79C8.56237 12.9261 8.77803 12.9999 9 12.9999C9.22197 12.9999 9.43763 12.9261 9.613 12.79L9.707 12.707L13.707 8.70701L13.79 8.61301C13.9393 8.42061 14.0132 8.18036 13.9979 7.93733C13.9826 7.69431 13.8792 7.46521 13.707 7.29302Z" fill="#EAEAEA"/>
            </svg>
            <p>
                {{ text }}
            </p>
        </div>
        <i v-if="showTrash" class="far fa-trash-alt" v-on:click="$emit('remove')"></i>
    </label>
  </template>
  
  <script>
  export default {
    name: 'SelectableItem',
    
    props: {
        text: String,
        selected:{
            type: Boolean,
            default: false
        },
        showTrash:{
            type: Boolean,
            default: false
        }
    },
  }
  </script>
  <style lang="scss" scoped>

  label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 12px 13px;
    border-radius: 6px;
    border: 1px solid transparent;
    display: flex;
    cursor: pointer;
    transition: 0.2s;
    div{
        display: flex;
        align-items: flex-start;
        gap: 8px;
        font-size: 14px;
        color: #1F1F1F;
        font-weight: 400;
    }
    svg{
        transition: 0.2s;
        min-width: 20px;
    }
    &.selected{
        border: 1px solid #8134FF;
        background: linear-gradient(90deg, #F6F0FF 0%, rgba(246, 240, 255, 0) 100%);
        div{
            font-weight: 700;
        }
        svg path{
            fill: #8134FF;
        }
    }
  }
  </style>