<template lang="html">
    <div class="Ranges">
        <div class="Ranges--Range" v-for="(range) in filter.ranges">
            <Input type="number" :label="$t('suite_from')" v-model="range.from" v-on:updateAfter1000Ms="updated()"/>
            <Input type="number" :label="$t('suite_to')" v-model="range.to" v-on:updateAfter1000Ms="updated()"/>
        </div>
        <p class="Ranges--Error" v-show="showError">El valor debe estar entre 16 y 100</p>
    </div>
  </template>
  
  <script>
  import Input from '~/components/Input/Input.vue'

  export default {
    name: 'Ranges',
    components:{
        Input,
    },
    props: {
        filter: Object
    },
    data(){
        return{
            showError: false
        }
    },
    methods:{
        updated(){
            let range = this.filter.ranges[0]
            if (range.from < 16 || range.from > 99 || range.from >= range.to) {
                this.showError = true
                return
            }
            if (range.to < 17 || range.to > 100 || range.to <= range.from) {
                this.showError = true
                return
            }
            this.showError = false
            this.$emit('update')
        }
    }
  }
  </script>
  <style lang="scss" scoped>
    .Ranges{
        display: flex;
        flex-direction: column;
        &--Range{
            display: flex;
            align-items: center;
            gap: 16px;
        }
        &--Error{
            color: red;
            font-size: 12px;
            text-align: justify;
            padding: 10px 0 0;
        }
    }
  </style>