<template lang="html">
    <div class="RankingValues">
        <RangeSlider v-model="value" :marks="true" :max="filter.rankingMax" :min="filter.rankingMin" v-on:drag-end="updated()"/>
        <p v-on:click="removeFilter()">Borrar selección</p>
    </div>
  </template>
  
  <script>
  import RangeSlider from '../../RangeSlider/RangeSlider.vue'
  export default {
    name: 'RankingValues',
    components:{
        RangeSlider
    },
    props: {
        filter: Object,
        totals: Array
    },
    data(){
        return{
            value:this.filter.rankingMin
        }
    },
    methods:{
        updated(){
            const vm = this
            this.filter.values = this.filter.values.map(function(v){
                v.selected = v.id == vm.value
                return v
            })
            this.$emit('update')
        },
        removeFilter(){
            this.value = this.filter.rankingMin
            this.filter.values = this.filter.values.map(function(v){
                v.selected = false
                return v
            })
            this.$emit('update')
        }
    }
  }
  </script>
  <style lang="scss" scoped>
    .RankingValues{
        display: flex;
        flex-direction: column;
        gap: 16px;
        p{
            font-size: 12px;
            color: red;
            text-align: justify;
            cursor: pointer;
        }
    }
  </style>