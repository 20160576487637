<template lang="html">
    <div class="ButtonTabs">
        <button v-for="(tab, index) in tabs" :class="{'selected':selected == index}" v-on:click="$emit('select',tab.id)">{{tab.text}}</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ButtonTabs',
    props: {
        selected: {
            type: Number,
            default: -1
        },
        tabs:{
            type: Array
        }

    }
  }
  </script>
  <style lang="scss">
  .ButtonTabs{
    display: flex;
    font-family: Lato;
    gap:6px;
    padding-bottom: 8px;
    button{
        border: 1px solid #c8c8c8;
        padding: 6px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 6px;
        background: white;
        cursor: pointer;
        &.selected{
            background: #F6F0FF;
            border: 1px solid #8134FF
        }
    }
  }
  </style>