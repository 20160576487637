
<template >
    <div class="WatUxRateTypes">
        <p><span></span>Tasa de Éxito</p>
        <p><span></span>Tasa de Error</p>
        <p><span></span>Tasa de Timeout</p>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import Chart from './Chart'
  export default {
    name: 'WatUxRateTypes',
  }
  </script>
  
  <style lang="scss" scoped>
  .WatUxRateTypes{
    display: flex;
    gap: 16px;
    font-family: 'Lato';
    font-weight: 900;
    font-size: 16px;
    p{
    display: flex;
    align-items: center;
    gap: 10px;
    &:nth-child(1) span{
        background: #96C800;
    }
    &:nth-child(2) span{
        background: #FF1564;
    }
    &:nth-child(3) span{
        background: #FFBF6F;
    }
    }
    span{
    padding: 5px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    }
  }
  </style>
  