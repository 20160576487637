
<template >
  <div class="WatUxQuantiMonitor">
    <h1>Datos generales de la tarea</h1>
    <div class="WatUxQuantiMonitor--Data">
      <WatUxRateTypes />
      <WatUxQuantiProgressBar v-if="columnLabels.length == 0" :successCount="successCount(0)" :errorCount="errorCount(0)" :timeoutCount="timeoutCount(0)"/>
      <WatUxQuantiProgressBar v-if="columnLabels.length > 0" v-for="(cl, index) in columnLabels" :name="cl" :successCount="successCount(index)" :errorCount="errorCount(index)" :timeoutCount="timeoutCount(index)"/>
      <div class="WatUxQuantiMonitor--InfoBox">
        <WatUxQuantiInfoBox icon="time" title="Tiempo Medio" :text="`El tiempo medio de las tareas ha sido de <span>${averageTime}</span> segundos`"/>
        <WatUxQuantiInfoBox :count="averageClicks" icon="click" title="Media clicks" :text="`La cantidad media de clicks ha sido de <span>${averageClicks}</span>`"/>
        <WatUxQuantiInfoBox :count="averageViews" icon="views" title="Media páginas vistas" :text="`La cantidad media de páginas vistas ha sido de <span>${averageViews}</span>`"/>
      </div>
    </div>
    <ChartNew 
      v-for="(q, i) in uxQuantiData"
      :id="'WatChartUx-'+i"
      :ref="'WatChartUx-'+reportIndex+'-'+i"
      :testId="test.test.testId"
      :question="q" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WatUxQuantiProgressBar from './WatUxQuantiProgressBar'
import WatUxQuantiInfoBox from './WatUxQuantiInfoBox'
import WatUxRateTypes from './WatUxRateTypes'
import ChartNew from './ChartNew.vue'

export default {
  name: 'WatUxQuantiMonitorV3',
  components: {
    WatUxQuantiProgressBar,
    WatUxQuantiInfoBox,
    WatUxRateTypes,
    ChartNew
  },
  props:{
    question:Object,
    taskNum:Number,
    reportIndex: Number,
    test:Object
  },
  computed:{
    ...mapGetters([
      'reportTab'
    ]),
    columnLabels(){
      const chart = this.question.uxQuantiData[0].chart
      if(chart == undefined) return []
      return chart.columnLabels
    },
    averageTime(){
      return this.getValue(-4)
    },
    averageClicks(){
      return this.getValue(-3)
    },
    averageViews(){
      return this.getValue(-2)
    },
    uxQuantiChartIds(){
      let questionIds = [-4,-3,-2]
      let task = this.getTaskByTaskNum(this.taskNum)
      if(task.uxQuantiData.desktopSuccessUrls.length > 1) questionIds.push(-8)
      return questionIds
    },
    uxQuantiData(){
      let questions = []
      let ids = this.uxQuantiChartIds
      for(let q of this.question.uxQuantiData){
        if(ids.includes(q.questionId)){
          questions.push(q)
        }
      }
      return questions
    }
  },
  methods:{
    generate(){
      for(let index in this.uxQuantiData){
        const ref = this.$refs['WatChartUx-'+this.reportIndex+'-'+index];
        if (this.uxQuantiData[index].chart === undefined) {
          setTimeout(() => {
            ref.generate(false);
          }, 500);
        } else {
          ref.generate(false);
        }
      }
    },
    successCount(index){
      return this.getyTotals(-1, 0, index)
    },
    errorCount(index){
      return this.getyTotals(-1, 1, index)
    },
    timeoutCount(index){
      return this.getyTotals(-1, 2, index)
    },
    getQuestionsById(ids){
      return this.question.uxQuantiData.filter(q => ids.includes(q.questionId) && q.taskNum == this.taskNum )
    },
    getyTotals(questionId, valueIndex, valueElementIndex){
      const question = this.getQuestionsById([questionId])[0]
      if(question.chart == undefined) return 0
      if(question.chart.values[valueIndex][0][valueElementIndex] == undefined) return 0
      return question.chart.values[valueIndex][0][valueElementIndex].percentY
    },
    getValue(questionId){
      const question = this.getQuestionsById([questionId])[0]
      if(question.chart == undefined) return 0
      return Math.round(question.chart.average * 10) / 10;
    },
    getTaskByTaskNum(taskNum){
      return this.test.testTasks.filter(t => t.taskNum == taskNum)[0]
    },
  }
}
</script>

<style lang="scss" scoped>
.WatUxQuantiMonitor{
  padding: 48px 32px;
  gap: 24px;
  background: #FFFFFF;
  border: 1px solid lightgray;
  border-top: 4px solid #8134FF;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  h1{
    margin: 0!important;
    color: white; 
    font-family: 'Bariol Bold';
    font-weight: 700;
    font-size: 24px;
    background: #8134FF;
    border-radius: 4px 4px 0px 0px;
    padding: 8px 26px;
    width: fit-content;
    position: absolute;
    top: -42px;
    left: -1px;
  }
  &--Data{
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 16px;
    background: conic-gradient(from 180deg at 50% 50%, #FAFAFA 0deg, #EBF5FF 63.75deg, #F8F9FE 180deg, #FBF5FF 296.25deg, #FAFAFA 360deg), #FFFFFF;
  }
  &--InfoBox{
    display: flex;
    gap: 10px;
  }
  &--Chart{
    border: 1px solid #EAEAEA;
  }
}
</style>
