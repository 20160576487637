
<template >
  <div class="WatUxQuantiMonitorSummary">
    <h1>Resumen general de las tareas</h1>
    <div class="WatUxQuantiMonitorSummary--Data">
      <div class="WatUxQuantiMonitorSummary--Header">
        <p>Nombre de las tareas</p>
        <WatUxRateTypes />
      </div>
      <div class="WatUxQuantiMonitorSummary--Task" v-for="(t, taskIndex) in uxTasks" v-if="uxTasks.length > 0">
        <div class="WatUxQuantiMonitorSummary--TaskInfo">
          <i class="fas fa-bars"></i>
          <span>{{t.taskNum}}</span>
          {{ t.name }}
        </div>
        <WatUxQuantiProgressBar hideProgress :successCount="successCount(t.taskNum)" :errorCount="errorCount(t.taskNum)" :timeoutCount="timeoutCount(t.taskNum)"/>
      </div>
      <div class="WatUxQuantiMonitorSummary--Task" v-for="(t, taskIndex) in clickTestTasks" v-if="clickTestTasks.length > 0">
        <div class="WatUxQuantiMonitorSummary--TaskInfo">
          <i class="fas fa-bars"></i>
          <span>{{t.taskNum}}</span>
          {{ t.name }}
        </div>
        <WatUxQuantiProgressBar hideProgress :successCount="successCount(t.taskNum)" :errorCount="errorCount(t.taskNum)" :timeoutCount="timeoutCount(t.taskNum)"/>
      </div>
    </div>
    <h1>Datos globales del estudio</h1>
    <ButtonTabs 
      v-if="isV3Chart && uxQuantiQuestionsGlobal.length > 0"
      :selected="selectedColumnLabel"
      :tabs="columnLabels"
      v-on:select="$event;generateSummaryCharts($event)"/>
    <div class="WatUxQuantiMonitorSummary--Charts">
      <ChartBasic v-for="(q, index) in uxQuantiQuestionsGlobal" :ref="'ChartBasic-' + index" :question="q" :personalization="personalization" :isV3Chart="isV3Chart"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WatUxQuantiProgressBar from './WatUxQuantiProgressBar'
import WatUxRateTypes from './WatUxRateTypes'
import ChartBasic from './ChartBasic'
import ButtonTabs from './ButtonTabs.vue'
export default {
  name: 'WatUxQuantiMonitorSummary',
  components: {
    WatUxQuantiProgressBar,
    WatUxRateTypes,
    ChartBasic,
    ButtonTabs
  },
  props:{
    tasks:{
      type: Array,
      default: ()=> []
    },
    reportIndex: Number,
    test:Object,
    personalization: Object,
    type: String,
    isV3Chart:{
      type: Boolean,
      default: false
    },
    reportQuestions: Array,
  },
  computed:{
    uxQuantiQuestionsGlobal(){
      const questions = this.getQuestionsByIdNoTaskNum([-5,-6,-7]) || []
      return questions
    },
    uxTasks(){
      return this.tasks.filter(t => t.taskType == 'UX_QUANTI')
    },
    clickTestTasks(){
      return this.tasks.filter(t => t.taskType == 'CLICK_TEST')
    },
    columnLabels(){
      return this.uxQuantiQuestionsGlobal[0].chart.columnLabels.map((c,i) => { return {id:i, text:c} })
    }
  },
  data(){
    return{
      selectedColumnLabel:-1
    }
  },
  methods:{
    successCount(taskNum){
      return this.getyTotals(-1, 0, taskNum)
    },
    errorCount(taskNum){
      return this.getyTotals(-1, 1, taskNum)
    },
    timeoutCount(taskNum){
      return this.getyTotals(-1, 2, taskNum)
    },
    getQuestionsById(ids, taskNum){
      const clickTestTasks = this.clickTestTasks
      const uxTasks = this.uxTasks
      let vm =this
      if(clickTestTasks.length > 0){
        const task = this.clickTestTasks.filter(t => t.taskNum == taskNum)[0]
        if(!this.isV3Chart) return task.chartQuestions[0].clickTestData.filter(q => ids.includes(q.questionId) && q.taskNum == taskNum )
        else return task.questions[0].clickTestData.filter(q => ids.includes(q.questionId) && q.taskNum == taskNum )
      }
      else if(uxTasks.length > 0){
        const task = this.uxTasks.filter(t => t.taskNum == taskNum)[0]
        if(!this.isV3Chart) return task.chartQuestions[0].uxQuantiData.filter(q => ids.includes(q.questionId) && q.taskNum == taskNum )
        return task.questions[0].uxQuantiData.filter(q => ids.includes(q.questionId) && q.taskNum == taskNum )
      } 
    },
    getQuestionsByIdNoTaskNum(ids){
      const clickTestTasks = this.clickTestTasks
      const uxTasks = this.uxTasks
      if(clickTestTasks.length > 0){
        if(!this.isV3Chart) return this.clickTestTasks[0].chartQuestions[0].clickTestData.filter(q => ids.includes(q.questionId) && q.taskNum == this.clickTestTasks[0].chartQuestions[0].clickTestData[0].taskNum )
        else return this.clickTestTasks[0].questions[0].clickTestData.filter(q => ids.includes(q.questionId) && q.taskNum == this.clickTestTasks[0].questions[0].clickTestData[0].taskNum )
      }
      else if(uxTasks.length > 0){
        if(!this.isV3Chart) return this.uxTasks[0].chartQuestions[0].uxQuantiData.filter(q => ids.includes(q.questionId) && q.taskNum == this.uxTasks[0].chartQuestions[0].uxQuantiData[0].taskNum )
        else return this.uxTasks[0].questions[0].uxQuantiData.filter(q => ids.includes(q.questionId) && q.taskNum == this.uxTasks[0].questions[0].uxQuantiData[0].taskNum )
      }

    },
    getyTotals(questionId, index, taskNum){
      const question = this.getQuestionsById([questionId], taskNum)[0]
      if(question.chart == undefined) return 0
      if(this.isV3Chart)  return question.chart.rowTotals[index].number
      return question.chart.yTotals[index]
    },
    generateSummaryCharts(columnLabelIndex){
      this.selectedColumnLabel = columnLabelIndex
      for(let index in this.uxQuantiQuestionsGlobal){
        this.$refs['ChartBasic-' + index].generate(this.selectedColumnLabel)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.WatUxQuantiMonitorSummary{
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  h1{
    font-family: 'Bariol Bold';
    font-size: 30px;
    margin: 0;
  }
  &--Data{
    background: white;
    padding: 32px;
    margin-bottom: 30px;
  }
  &--Header{
    display: flex;
    justify-content: space-between;
    p{
      font-family: 'Lato';
      font-weight: 900;
      font-size: 18px;
    }
  }
  &--Task{
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    border-bottom: 1px dashed #C8C8C8;
    padding: 16px;
  }
  &--TaskInfo{
    display: flex;
    align-items: center;
    gap: 15px;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    i{
      color: #C8C8C8;
    }
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      background: #8134FF;
      color: white;
      min-width: 28px;
      height: 28px;
      border-radius: 28px;
      font-family: 'Bariol';
      font-weight: 700;
      font-size: 16px;
    }
  }
  &--Charts{
    display: flex;
    gap: 5px;
    height: 280px;
  }
}
</style>