<template lang="html">
  <div class="ChartBasic">
      <div
        :id="'MonitorHighchart_'+_uid"
        ref="chart">
      </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue';
import Highcharts from "highcharts";
export default {
  name: 'ChartBasic',
  props: {
    question:Object,
    personalization: Object,
    isV3Chart: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fontFamily:'Lato',
      Highcharts: Highcharts,
      _uid:getCurrentInstance().uid,
      chartConfig:{}
    }
  },
  methods:{
    generate(columnLabelIndex){
      this.generateConfig(columnLabelIndex)
      this.fontFamily = this.personalization != undefined && this.personalization.font != '' ? this.personalization.font : this.chartConfig.chart.style.fontFamily
      this.chartConfig.colors = this.personalization != undefined && this.personalization.colors && this.personalization.colors.length > 0 ? this.personalization.colors : this.chartConfig.colors
      this.chartConfig.chart.style.fontFamily =  this.personalization != undefined && this.personalization.font != '' ? this.personalization.font : this.chartConfig.chart.style.fontFamily
      this.chartConfig.title.text = `<b>${this.question.questionText}</b>`
      this.chart = this.Highcharts.chart('MonitorHighchart_'+this._uid,this.chartConfig)
      this.chart.reflow()
    },
    generateConfig(columnLabelIndex){
      this.chartConfig = {
        colors:["#8134ff","#FF1564","#FD7328","#361466","#895AD2","#96C800","#FD4D40","#f9c39f","#ede8f2","#669d98","#a81187","#8aefbb"],
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            style:{
              fontFamily: 'Lato'
            },
            spacing:[0,0,0,0],
            marginBottom: 30
        },
        legend:{
          enabled: true,
          maxHeight: 70,
          navigation:{
            arrowSize: 9
          }
        },
        title: {
            text: 'Datos de navegadores',
            align: 'left',
            useHTML: true,
            x: 18,
            y: 30
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
              showInLegend: true,
              dataLabels: {
                enabled: false
              },
              startAngle: -90,
              endAngle: 90,
              center: ['50%', '75%'],
              size: '110%'
            }
        },
        exporting: {
           enabled: false,
        },
        series: [{
            name: this.question.questionText,
            type: 'pie',
            innerSize: '50%',
            data: this.getSeries(columnLabelIndex),
            animation: false

        }],
        credits: {
            enabled: false
        },
      }
    },
    getSeries(columnLabelIndex){
      if(this.isV3Chart) return this.getSeriesV3(columnLabelIndex)
      let data = []
      let index = 0      
      for(let xLabel of this.question.chart.xLabels){
        data.push([xLabel,this.question.chart.yTotals[index]])
        index++
      }
      return data
    },
    getSeriesV3(columnLabelIndex){
      let data = []
      const { columnLabels, values, rowLabels } = this.question.chart
      if(columnLabelIndex == undefined) columnLabelIndex = 0
      for(let i in values){
        data.push([rowLabels[i], values[i][0][columnLabelIndex].percentY])
      }
      return data
    }
  },
  mounted(){
    this.generate(null)
  }
}
</script>
<style lang="scss" scoped>
.ChartBasic{
  width: 100%;
  height: 280px;
  background: white;
  div{
    height: 100%;
    width: 100%;
  }
}
</style>
