<template lang="html">
    <div class="CollapsableItem" :class="{'collapsed':active}">
        <div class="CollapsableItem--Header" v-on:click="collapse()">
          <h1><span v-show="num != -1">{{ num }}.</span> {{ text }}</h1>
          <div class="CollapsableItem--Actions">
            <div v-show="count > 0" class="CollapsableItem--Badge">{{ count }}</div>
            <i class="fas fa-chevron-down"></i>
          </div>
        </div>
        <div v-show="active" class="CollapsableItem--Content">
          <slot></slot>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CollapsableItem',
    props: {
        num: {
          type: [Number, String],
          default: -1
        },
        text: [Number, String],
        count:{
          type: Number,
          default: 0
        }
    },
    data(){
      return{
        active: true
      }
    },
    methods:{
      collapse(){
        this.active = !this.active;
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  h1{
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    span{
        background-image: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }
  }
  .collapsed .CollapsableItem--Header i{
    transition: 0.5s;
    transform: rotate(180deg);
  }
  .CollapsableItem{
    font-family: Lato;
    border-bottom: 1px solid #EAEAEA;
    &--Header{
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: justify;
        cursor: pointer;
        gap:12px;
        width: 100%;
        i{
          transform: rotate(0deg);
        }
    }
    &--Actions{
      display: flex;
      gap: 12px;
    }
    &--Badge{
      color: white;
      background: #8134FF;
      width: 23px;
      height: 17px;
      padding: 4px 8px;
      border-radius: 16px;
      border:1px solid #8134FF;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    &--Content{
      padding: 0 24px 16px 24px;
    }
  }
  </style>