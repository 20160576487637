<template lang="html">
    <div class="WatMonitor">
      <SubHeader isWatMonitor isWatMonitorV3 :isSharedReport="isSharedReport"
        :excelIsCompleted="excelIsCompleted"
        :excelInProgress="excelInProgress"
        v-on:download-excel="downloadExcelBasic()" 
        v-on:download-excel-aggregated="downloadExcelAggregated()"
        v-on:loaded-excel-aggregated="loadedExcelAggregated()"
        v-on:new-report="addReport()" 
        v-on:save-report="saveReportSubHeader()" 
        v-on:share-report="shareReportSubHeader()" 
        v-on:delete-report="deleteReportSubHeader()"/>
        <div v-for="(report, reportIndex) in reports">
            <MonitorNew 
                v-if="reportIndex == reportTab"
                :ref="'Monitor-'+reportIndex"
                :report="report"
                :test="test"
                :i18n="i18n"
                :testId="testId"
                :regions="regions"
                :nielsen="nielsen"
                :provinces="provinces"
                :questionsToFilter="questionsToFilter"
                :filterVariables="filterVariables"
                :progressPercent="excelAggregatedStatus.progressPercent"
                :showProgressBar="showProgressBar"
                v-on:remove-variable="beforeRemoveFilterVariable($event)"
                v-on:save-filter="saveFilter($event)"
                v-on:fetchReportWithFilters="fetchChartWithFilters()"
                v-on:fetch-question="fetchOneQuestion($event)"
                v-on:open-color-editor="openColorEditor()"
            />
        </div>
        <LoadingModal :title="loadingModalTitle" v-show="showLoadingModal"/>
        <ModalBasic :show="showColorEditor" v-on:close="closeColorEditor()">
            <template v-slot:content>
                <div class="ColorModal">
                    <h1>{{ $t('watmonitor_colors') }}</h1>
                    <p v-on:click="restorePersonalization()">{{ $t('watmonitor_restore_pers') }}</p>
                    <div class="ColorModal--Color" v-for="(c, index) in editingColors">
                        <div class="ColorModal--Input">
                            <i class="far fa-trash-alt" v-on:click="editingColors.splice(index,1)"></i>
                            <span :style="{'background':c}"></span>
                            <input type="text" v-model="editingColors[index]">
                        </div>
                        <img :src="require('~/assets/img/WatMonitor/colorpicker.svg')" v-on:click="editingColorIndex = index">
                        <div class="ColorPicker" v-if="index == editingColorIndex">
                            <svg v-on:click="editingColorIndex = -1" class="ColorPicker--Close" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-e2a0987a=""><path d="M13 1L1 13M1 1L13 13" stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-e2a0987a=""></path></svg>
                            <ColorPicker
                                theme="light"
                                :color="editingColors[editingColorIndex]"
                                :sucker-hide="false"
                                @changeColor="changeColor"
                            />
                        </div>
                    </div>
                    <p v-on:click="editingColors.push('#FFFFFF')">{{ $t('watmonitor_add_color') }}</p>
                    <Button :bgColor="'white'" :txtColor="'#8134FF'" v-on:click="savePersonalization()"> {{$t('watmonitor_action_save')}}</Button>
                </div>
            </template>
        </ModalBasic>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapMutations } from 'vuex'
  import store from '@/store'
  import MonitorNew from '~/components/WatMonitor/MonitorNew'
  import LoadingModal from '~/components/WatMonitor/LoadingModal'
  import SubHeader from './layouts/SubHeader'
  import ModalBasic from '~/components/ModalBasic'
  import Button from '@/components/Button.vue'
  import Swal from 'sweetalert2'
  import { groupBy, cloneDeep  } from 'lodash';
  import { formatCompanyQuestions, removeHTML, formatFilterQuestions, formatTestQuestionsByTasks } from '~/utils/watmonitor/'
  import Worker from "worker-loader!./worker.worker";
  import i18n from '@/i18n'
  import { ColorPicker } from 'vue-color-kit'
  import '~/assets/css/vue-color-kit.css'

  export default {
    name: 'WatMonitor',
    props: {
      id:String,
    },
    components:{
        MonitorNew,
        SubHeader,
        LoadingModal,
        ModalBasic,
        ColorPicker,
        Button
    },
    computed:{
        ...mapGetters([
          'isAuthenticated',
          'reportTab',
          'isObserver',
          'authToken'
      ])
    },
    data(){
        return{
            testId: this.id,
            isSharedReport: false,
            reportId: 0,
            provinces:[],
            regions:[],
            nielsen:[],
            companyQuestions:[],
            questionsToFilter:[],
            socioDemographicQuestionsToFilter:[],
            reports:[],
            isReportReady: false,
            loadingModalTitle:this.$t('watmonitor_generate_report'),
            stopQuestionsFetch: false,
            isExecutingFetchQuestions: false,
            currentIndexQuestion:0,
            filterVariables: [],
            showLoadingModal: false,
            fetchUniqueQuestion: false,
            i18n:i18n,
            excelAggregatedJob: 0,
            excelAggregatedStatus: {
                progressPercent: 0,
                generalStatus: "",
                excelUrl: ""
            },
            progressInterval: null,
            showProgressBar: false,
            excelIsCompleted: false,
            excelInProgress: false,
            showColorEditor: false,
            editingColors: [],
            editingColorIndex: -1
        }
    },
    methods:{
        ...mapMutations({
            updateReportsList : 'updateReportsList',
            addReportToReportsList: 'addReportToReportsList',
            removeReportFromReportsList: 'removeReportFromReportsList',
            updateReportTab : 'updateReportTab'
        }),
        async downloadExcel(serviceName, e){
            Swal.fire({
                title:this.$t('suite_modal_text_downloading_excel') + "...",
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                customClass:"swal-custom",
                icon: "info"
            })
            const testId = this.$route.params.id
            let vm = this
            await store.dispatch(serviceName, {
                testId: testId,
                reportId:this.reports[this.reportTab].identifier,
                data: e
            })
            .then((data) => {
                let FileSaver = require('file-saver');
                let blob = new Blob([ data ], {type: 'application/vnd.ms-excel'})
                saveAs(blob, vm.$t('watmonitor_test_results') + ' '+testId+'.xlsx', false);
                Swal.close()
            })
            .catch((response) => {
                Swal.close()
                if(response.status == 401){
                    store.dispatch('closeSession')
                    vm.$router.push({name:'login'})
                }
                else Swal.fire("",vm.$t('watmonitor_generation_report'),"warning")
            })
        },
        downloadExcelBasic(){
            this.downloadExcel('fetchCombinedExcel', null)
        },
        downloadExcelAggregated(){
            this.downloadExcel('fetchAggregatedExcel', {
                excelUrl: this.excelAggregatedStatus.excelUrl
            })
        },
        async loadedExcelAggregated(){
            const filters = this.$refs['Monitor-' + this.reportTab].generateExcelFilters()
            await store.dispatch('loadedExcelAggregated', {
                testId: this.$route.params.id,
                reportId: this.reports[this.reportTab].identifier,
                data: filters
            })
            .then((data) => {
                this.excelAggregatedJob = data.identifier;
                this.startProgressInterval()
            })
            .catch((data) => {
                let vm = this
                Swal.fire({
                    title: vm.$t('suite_error_downloading_excel_aggregated'),
                    icon: 'error'
                });
            })
        },
        startProgressInterval(){
            this.excelInProgress = true
            this.progressInterval = setInterval(() => {
                this.checkExcelAggregatedState();
            }, 2000);
        },
        stopProgressInterval(){
            clearInterval(this.progressInterval);
            this.showProgressBar = false;
            this.excelInProgress = false;
        },
        async checkExcelAggregatedState(){
            await store.dispatch('checkExcelAggregatedState', {
                excelJobId: this.excelAggregatedJob
            })
            .then((data) => {
                this.excelAggregatedStatus.generalStatus = data.generalStatus;
                this.excelAggregatedStatus.progressPercent = data.progressPercent;
                this.excelAggregatedStatus.excelUrl = data.excelUrl;
                this.showProgressBar = true;
                if ((this.excelAggregatedStatus.progressPercent == 100 && this.excelAggregatedStatus.generalStatus == "GS_2_FINISHED") || this.excelAggregatedStatus.generalStatus == "GS_3_ERROR") {
                    this.stopProgressInterval();
                }
                if (this.excelAggregatedStatus.progressPercent == 100 && this.excelAggregatedStatus.generalStatus == "GS_2_FINISHED") {
                    this.excelIsCompleted = true;
                }
                if (this.excelAggregatedStatus.generalStatus == "GS_3_ERROR") {
                    let vm = this
                    Swal.fire({
                        title: vm.$t('suite_error_generating_excel_aggregated'),
                        icon: 'error'
                    });
                }
            })
            .catch((data) => {
                let vm = this
                Swal.fire({
                    title: vm.$t('suite_error_generating_excel_aggregated'),
                    icon: 'error'
                });
            })
        },
        saveTestAndReportIds(){
            this.testId = this.id
            this.isSharedReport = !isNaN(this.testId.split("-")[0]) && this.testId.split("-").length > 6
            if(this.isSharedReport){
                this.reportId = this.testId.split("-")[0]
                const hashSplit = this.testId.split("-")
                this.testId = hashSplit.splice(1).join("-")
            }
        },
        checkAccess(){
            if(!this.isAuthenticated && !this.isSharedReport){
                this.showNotLoggedMessage()
                return false
            }
            if(!this.isSharedReport && this.isPublicDashboard()){
                this.showNotAllowedMessage()
                return false
            }
            if(this.isSharedReport){
                this.requirePassword()
                return false
            }
            return true
        },
        async getWatMonitorAllData(){
            this.loadReportPreview()
            this.fetchTestVariables(false)
            await this.fetchTest();
            await this.fetchLovs();
            if(this.isCompanyTest()) await this.fetchCompanyQuestions()
            await this.fetchFilterQuestions()
            await this.fetchReports()
            if(this.isSharedReport) this.fetchReport({reportIndex:0,reportId:this.reportId})
            else this.fetchBasicReport();
        },
        fetchTestVariables(selectLastVariable){
            store.dispatch('fetchFilterVariables', {testId: this.testId})
            .then((data) => {
                this.filterVariables = data.map((v,i) => ({...v, selected: selectLastVariable && i == data.length - 1 ? true : false}));
            })
        },
        async fetchTest(){
            await store.dispatch(this.isPublicDashboard() ? 'getPublicTest' : 'getTest', { testId: this.testId })
            .then((data) => {
                data.testTasks = formatTestQuestionsByTasks(data.testTasks)
                this.test = data
            })
            .catch((data) => {
                this.showNotAllowedMessage()
            })
        },
        async fetchCompanyQuestions(){
            await store.dispatch(this.isPublicDashboard() ? 'getPublicCompanyQuestions' : 'getCompanyQuestions', { testId: this.testId })
            .then((data) => {
                this.companyQuestions = formatCompanyQuestions(data.questions)
            })
        },
        async fetchLovs(){
            await store.dispatch('getProvinces', { country: this.test.test.country.secondaryValue})
            .then((data) => {
                this.provinces = data
            })
            if(this.test.test.country.secondaryValue == 'ES'){
                await store.dispatch('getRegions', { country: this.test.test.country.secondaryValue})
                .then((data) => {
                    this.regions = data
                })
                await store.dispatch('getNielsenAreas', { country: this.test.test.country.secondaryValue})
                .then((data) => {
                    this.nielsen = data
                })
            }
        },
        async fetchFilterQuestions(){
            await store.dispatch(this.isPublicDashboard() ? 'getPublicFilterQuestionsV3' : 'getFilterQuestionsV3', { testId: this.testId })
            .then((data) => {
                this.questionsToFilter = formatFilterQuestions(data)
            })
        },
        async fetchReports(){
            let vm = this
            await store.dispatch('getAllReports',{testId:this.testId})
            .then(({data}) => {
                this.reports = data
                this.updateReportsList(data)
            })
            .catch(({response}) => {
                Swal.fire("",vm.$t('watmonitor_report_Error'),"error")
            })
        },
        async fetchBasicReport() {
            const vm = this
            await store.dispatch('getBasicReportV3',{testId:this.testId})
            .then(async ({data}) => {
                const t = this.generateReportData(data);
                vm.reports[0] = t
                this.finishReportPreview();
                this.fetchAllQuestionsChart();
            })
        },
        fetchChartWithFilters(){
            const questions = _.cloneDeep(this.reports[this.reportTab].questions)
            const index = questions.findIndex(q => q.isInViewport);
            this.currentIndexQuestion = index < 0 ? 0 : index

            if(this.isExecutingFetchQuestions) {
                this.stopQuestionsFetch = true
            }
            else{
                this.fetchAllQuestionsChart()
            }
            this.excelIsCompleted = false;
        },
        fetchAllQuestionsChart(){
            this.isExecutingFetchQuestions = true
            const questions = _.cloneDeep(this.reports[this.reportTab].questions)
            this.reports[this.reportTab].questions[this.currentIndexQuestion].isFetched = true
            this.fetchQuestion({question: questions[this.currentIndexQuestion], chartIndex: this.currentIndexQuestion})
        },
        fetchOneQuestion(event){
            this.fetchUniqueQuestion = true
            this.fetchQuestion(event)
        },
        fetchQuestion(e){
            if(e.question.questionType == "HEATMAP") this.fetchHeatMap(e.question, e.chartIndex)
            else if(e.question.questionType == "DATE" || e.question.questionType == "TEXT" || e.question.questionType == "MULTITEXT" || e.question.questionType == "TEXTAREA" || e.question.questionType == "IMAGE_URL") this.fetchList(e.question, e.chartIndex, e.question.page != undefined ? e.question.page : 0 , 10)
            else if(e.question.questionType == 'WATUX') this.fetchUxdata(e.question, e.chartIndex)
            else if(e.question.questionType == 'WATUX_QUANTI') this.fetchUxQuantiData(e.question, e.chartIndex)
            else if(e.question.questionType == 'CLICK_TEST') this.fetchClickTestData(e.question, e.chartIndex)
            else if(e.question.questionType != "LABEL") this.fetchChart(e.question, e.chartIndex)
            else if(e.question.questionType == "LABEL") this.receivedChartFromWebWorker({data:{result:undefined}} ,e.question, e.chartIndex)
        },
        fetchHeatMap(q, chartIndex){
            const { testId } = this;
            q.page = null
            q.pageSize = null
            const endPoint = '/clientDashboard'+(isNaN(testId)? "/public/" : "/")+'test/' + testId + '/dashboardHeatmapv2'
            this.callWebWorker(q, chartIndex, endPoint)
        },
        fetchList(q, chartIndex, page, pageSize){
            const { testId } = this;
            q.page = page
            q.pageSize = pageSize
            const endPoint = '/clientDashboard'+(isNaN(testId)? "/public/" : "/")+'test/' + testId + '/dashboardTextv3'

            this.callWebWorker(q, chartIndex, endPoint)
        },
        async fetchUxdata(q, chartIndex){
            const { testId } = this;
            const endPoint = '/clientDashboard'+(isNaN(testId)? "/public/" : "/")+'test/' + testId + '/dashboardUxDatav2'
            this.callWebWorker(q, chartIndex, endPoint)
        },
        async fetchUxQuantiData(q, chartIndex){    
            let index = 0        
            for(let uxQuestion of q.uxQuantiData){
                uxQuestion.xDimension = uxQuestion.xDimensions[0]
                uxQuestion.isUxQuantiQuestion = true
                uxQuestion.isUxQuantiLastQuestion = index + 1 == q.uxQuantiData.length
                this.fetchChart(uxQuestion, chartIndex)
                index++
            }
        },
        async fetchClickTestData(q, chartIndex){
            const { testId } = this;
            const endPoint = '/clientDashboard' + (isNaN(testId) ? "/public/" : "/") + 'test/' + testId + '/dashboardClickTestDatav2'
            
            for(let clickTestData of q.clickTestData){
                clickTestData.xDimension = clickTestData.xDimensions[0]
                clickTestData.isClickTestQuestion = true
                this.fetchChart(clickTestData, chartIndex)
            }
            this.callWebWorker(q, chartIndex, endPoint)
        },
        fetchChart(q, chartIndex){
            const { testId } = this;
            const endPoint = '/clientDashboard' + (isNaN(testId) ? "/public/" : "/") + 'test/' + testId + '/dashboardv3'
            this.callWebWorker(q, chartIndex, endPoint)
        },
        callWebWorker(q, chartIndex, endPoint){
            const vm = this
            const chartJsonData = {
                xDimension: q.xDimension,
                yDimension: q.yDimension,
                xFilter: q.xFilter,
                order: q.order,
                questionType: q.questionType,
                textDimension: q.textDimension,
                page: q.page,
                pageSize: q.pageSize,
                heatmapDimension: q.heatmapDimension
            }
            const dataToSend = {
                q:JSON.stringify(chartJsonData),
                endPoint:endPoint,
                authToken: this.authToken
            };
            const worker = new Worker();
            worker.postMessage(dataToSend);
            worker.onmessage = function (event) {
                vm.receivedChartFromWebWorker(event, q, chartIndex)
            };
        },
        checkIfUxQuantiQuestionsAreLoaded(questions){
            return questions.every(obj => obj.chart !== undefined);
        },
        receivedChartFromWebWorker(event, q, chartIndex){
            let chart = event.data.result
            let vm = this
            if(chart == undefined) {
                chart = {values: [],xLabels: [],xTotals: [],yLabels: [],yTotals: []}
            } 
            if(q.questionType == 'HEATMAP') chart = this.formatHeatmapChart(chart, q)
            if(q.isClickTestQuestion){
                let uxQuestion = this.reports[this.reportTab].questions[chartIndex]
                const chartIndexClickTest = this.getUxClickTestQuestionIndex(uxQuestion, q.questionId, q.taskNum)
                uxQuestion.clickTestData[chartIndexClickTest].chart = chart
                return
            }
            if(q.isUxQuantiQuestion){
                let uxQuestion = this.reports[this.reportTab].questions[chartIndex]
                const chartIndexUxQuanti = this.getUxQuantiQuestionIndex(uxQuestion, q.questionId, q.taskNum)
                uxQuestion.uxQuantiData[chartIndexUxQuanti].chart = chart
                if(this.checkIfUxQuantiQuestionsAreLoaded(this.reports[this.reportTab].questions[chartIndex].uxQuantiData)){
                    this.receivedChartFromWebWorker({data:{result:undefined}},uxQuestion, chartIndex)
                }
                return
            }
            this.reports[this.reportTab].questions[chartIndex].chart = chart
            this.reports[this.reportTab].questions[chartIndex].firstLoadCompleted = true
            
            this.generateChart(chartIndex)
            const firstQuestion = this.reports[this.reportTab].questions[0]
            const nextQuestion = this.reports[this.reportTab].questions[this.currentIndexQuestion + 1]
            if(this.fetchUniqueQuestion){
                this.reports[this.reportTab].questions[this.currentIndexQuestion].isFetched = false 
                this.currentIndexQuestion = 0
                this.isExecutingFetchQuestions = false
                this.fetchUniqueQuestion = false
            }
            else if(this.stopQuestionsFetch){
                this.stopQuestionsFetch = false
                this.reports[this.reportTab].questions = this.reports[this.reportTab].questions.map(q => {q.isFetched = false; return q}) 
                this.fetchAllQuestionsChart()
            }
            else if(nextQuestion == undefined && !firstQuestion.isFetched){
                this.currentIndexQuestion = 0
                this.fetchAllQuestionsChart()
            }
            else if((nextQuestion == undefined && firstQuestion.isFetched) || (nextQuestion != undefined && nextQuestion.isFetched)){
                this.currentIndexQuestion = 0
                this.reports[this.reportTab].questions = this.reports[this.reportTab].questions.map(q => {q.isFetched = false; return q}) 
                this.isExecutingFetchQuestions = false
                this.$refs['Monitor-' + this.reportTab].generateSummary()
            }
            else {
                this.currentIndexQuestion++
                this.fetchAllQuestionsChart()
            }
        },
        formatHeatmapChart(chart,q){
            let heatMapPointsDefinition = this.getQuestionByIdAndTaskNum(q.questionId,q.taskNum).heatmapPointDefinitions
            q.heatmapPointDefinitions = heatMapPointsDefinition
            chart.heatmapPoints = _.map(chart.heatmapPoints[0][0], function (p) {
                let title = ""
                for(let hpd of heatMapPointsDefinition){
                    if(hpd.identifier == p.id) title = hpd.title
                }
                let element = {
                    title: title,
                    comment:p.com,
                    identifier:p.id,
                    imageIndex:p.imgIdx,
                    type:p.typ,
                    x:p.x,
                    y:p.y
                }
                return element
            })
            return chart
        },
        generateChart(chartIndex) {
            const monitorComponent = this.$refs['Monitor-' + this.reportTab];
            if (monitorComponent && monitorComponent.generateChart) {
                monitorComponent.generateChart(chartIndex);
            } else {
                console.error('Cannot generate chart: Monitor component or method not found.');
            }
        },
        generateReportData(reportData){
            let questions = reportData.questions
            if(!this.test.test.hideDashboardSocioDemographics && !this.isCompanyTest() && reportData.basicReport) {
                // Added to initially request the chart with regions as province groups
                reportData.socioDemographicQuestions[2].xDimension.groups = this.getProvinceGroups()
                questions = reportData.socioDemographicQuestions.concat(questions)
            }
            if(this.isCompanyTest()) {
                questions = this.companyQuestions.concat(questions)
            }
            if(this.test.test.type == 'WEB_TEST'){
                questions = this.getUxTaskQuestions().concat(questions)
                questions = this.getUxQuantiTaskQuestions(reportData).concat(questions)
                questions = this.getClickTestQuestions(reportData).concat(questions)
            }
            questions = this.orderQuestionsByTaskNum(questions)
            reportData.questions = this.parseQuestions(questions)
            return reportData
        },
        getUxQuantiTaskQuestions(report){
            let uxQuantiDatas = []
            if(report.uxQuantiData && report.uxQuantiData.length > 0){
                let taskNum = 1
                for(let uxQuestion of report.uxQuantiData){
                    if(taskNum < uxQuestion.taskNum) taskNum = uxQuestion.taskNum
                    if(uxQuestion.taskNum == taskNum) {
                        uxQuantiDatas.push({
                            questionId: -99,
                            questionType: "WATUX_QUANTI",
                            graphType: "WATUX_QUANTI",
                            questionText: this.$t('watmonitor_watux_Results') ,
                            resultType: "VALUE",
                            taskNum: uxQuestion.taskNum,
                            uxQuantiData: this.getUxQuantiDatas(report, uxQuestion),
                            yDimension:{},
                            xDimension:{},
                            chart:{
                                answersNum:0
                            }
                        })
                        taskNum++
                    }
                }
            }
            return uxQuantiDatas
        },
        getUxQuantiDatas(report, uxQuestion){
            let questions = report.uxQuantiData.filter(uqd => uqd.taskNum == uxQuestion.taskNum)
            let questions2 = []
            let questionIds = []
            for(let q of questions){
                if(questionIds.includes(q.questionId)){
                    questions2[questionIds.indexOf(q.questionId)] = q
                }
                else {
                    questions2.push(q)
                    questionIds.push(q.questionId)
                }
            }
            return questions2
        },
        getUxTaskQuestions(){
            var uxDatas = []
            for(let task of this.test.testTasks){
                if(task.uxData && task.taskType == 'UX_VIDEO'){
                    const data = {
                        questionId: 0,
                        questionType: "WATUX",
                        questionText: this.$t('watmonitor_watux_Results') ,
                        resultType: "VALUE",
                        taskNum: task.taskNum,
                        textDimension: {taskNum: task.taskNum, questionNum: 0},
                        xAxisTitle: "",
                        xAxisValues: [],
                        xDimension: {},
                        yAxisValues: [],
                        yDimension: {}
                    }
                    uxDatas.push(data)
                }
            }
            return uxDatas
        },
        getClickTestQuestions(report){
            let clickTestDatas = []
            if(report.clickTestData && report.clickTestData.length > 0){
                let taskNum = 1
                for(let clickTestQuestion of report.clickTestData){
                    if(taskNum < clickTestQuestion.taskNum) taskNum = clickTestQuestion.taskNum
                    if(clickTestQuestion.taskNum == taskNum) {
                        clickTestDatas.push({
                            imageUrl: this.getTaskByNum(taskNum).clickTestData.imageUrl,
                            questionId: -99,
                            questionType: "CLICK_TEST",
                            graphType: "CLICK_TEST",
                            questionText: this.$t('watmonitor_watux_Results') ,
                            resultType: "VALUE",
                            taskNum: clickTestQuestion.taskNum,
                            clickTestData: report.clickTestData.filter(uqd => uqd.taskNum == clickTestQuestion.taskNum),
                            textDimension: {taskNum: clickTestQuestion.taskNum, questionNum: 0},
                            xAxisTitle: "",
                            xAxisValues: [],
                            xDimension: {},
                            yAxisValues: [],
                            yDimension: {},
                            chart:{
                                answersNum:0
                            }
                        })
                        taskNum++
                    }
                }
            }
            return clickTestDatas
        },
        orderQuestionsByTaskNum(questions){
            let groupedQuestions = _.groupBy(questions,'taskNum')
            groupedQuestions = _.flatMap(groupedQuestions)
            questions = groupedQuestions.sort((a, b) => (Number.parseInt(a.taskNum) >= Number.parseInt(b.taskNum)) ? 1 : -1)
            return questions
        },
        parseQuestions(reportQuestions){
            let questions = reportQuestions
            for(let q of questions){
                q.questionText = removeHTML(q.questionText)
                if(q.questionType == 'NUMBER' || q.questionType == 'MULTINUMBER'){
                    if(q.xDimension.ranges) q.ranges = q.xDimension.ranges
                    else q.ranges = [{from:q.numMin!= undefined ? q.numMin : 0,to:q.numMax != undefined ? q.numMax : 100}]
                } 
                if(q.graphType == "LIST") q.page = 0
                const question = this.getQuestionByIdAndTaskNum(q.questionId, q.taskNum)
                if(question.questionType == 'IMAGE_CHOICE'){
                    q.images = question.values.map(function(v){return v.image})
                }
            }
            return questions
        },
        async fetchReport(e){
            /*await store.dispatch('getReport',{testId:this.testId,reportId:e.reportId})
            .then(async ({data}) => {
                this.generateReportData(e.reportIndex, data)
            })
            .catch(({response}) => {
                Swal.fire("",vm.$t('watmonitor_report_Error'),"error")
            })*/
        },
        getProvinceGroups(){
            const nielsen = _.cloneDeep(this.nielsen)
            let groups = []
            for(let r of nielsen){
                groups.push({groupName:r.value,values:this.getProvincesByNielsenId(r.id).map(function(v,i){return v.id})})
            }
            return groups
        },
        getProvincesByParentId(id){
            let provinces = []
            const provincesClone = _.cloneDeep(this.provinces)
            for(let p of provincesClone){
                if(p.parentId == id) provinces.push(p)
            }
            return provinces
        },
        getProvincesByNielsenId(id){
            let provinces = []
            const provincesClone = _.cloneDeep(this.provinces)
            for(let p of provincesClone){
                if(p.nielsenId == id) provinces.push(p)
            }
            return provinces
        },
        getTaskByNum(taskNum){
            const testTasks = _.cloneDeep(this.test.testTasks)
            return testTasks.filter(t => t.taskNum == taskNum)[0]
        },
        getQuestionByIdAndTaskNum(qId,tNum){
            const provinces = _.cloneDeep(this.provinces)
            const companyQuestions = _.cloneDeep(this.companyQuestions)
            const testTasks = _.cloneDeep(this.test.testTasks)

            if(tNum == -1 && qId<=3){
                if(qId == 3) return {taskNum:-1,questionNum:3,values:[{id:0,value:"Hombre", selected:false},{id:1,value:"Mujer",selected:false}]}
                else if(qId == 1) return {taskNum:-1,questionNum:1,values: provinces}
                else return {}
            }
            else if(tNum == -1 && qId >3){
                for(let q of companyQuestions){
                if(q.questionId == qId) return q
                }
            }
            for(let t of testTasks){
                if(tNum == t.taskNum){
                    if(t.questions){
                        for(let q of t.questions){
                        if(q.questionId == qId) return q
                        }
                    }
                }
            }
            return {}
        },
        getUxQuantiQuestionIndex(question, questionId){
            for(let index in question.uxQuantiData){
                if(question.uxQuantiData[index].questionId == questionId ) return index
            }
            return 0
        },
        getUxClickTestQuestionIndex(question, questionId){
            for(let index in question.clickTestData){
                if(question.clickTestData[index].questionId == questionId ) return index
            }
            return 0
        },
        isCompanyTest(){
            return this.test.test.companyTest
        },
        isPublicDashboard(){
            return isNaN(this.testId)
        },
        loadReportPreview(){
            this.showLoadingModal = true
            this.isReportReady = false
        },
        finishReportPreview(){
            this.showLoadingModal = false
            this.isReportReady = true
        },
        async saveFilter(variable){
            await store.dispatch('createFilterVariable',{ testId: this.testId, variable: variable})
            .then((data) => {
                this.successSwal('Variable creada', '')
                this.fetchTestVariables(true)
            })
            .catch((data) => {
                Swal.fire({
                title: '',
                text: 'La variable no ha podido crearse',
                customClass:"swal-custom",
                showConfirmButton: false,
                icon: 'error',
                timer: 3000
                });
            })
        },
        beforeRemoveFilterVariable(filterVariable){
            const vm  = this
            Swal.fire({
                title: '',
                text: "¿Deseas eliminar la variable: '"+ filterVariable.name+"' ?",
                type: "warning",
                showCancelButton: true,
                customClass:"swal-customWarning",
                confirmButtonColor: '#FF1564',
                confirmButtonText: "Sí, eliminar",
            }).then((result) => {
                if (result.isConfirmed) vm.removeFilterVariable(filterVariable)
            })
        },
        async removeFilterVariable(filterVariable){
            await store.dispatch('removeFilterVariable',{ variableId: filterVariable.identifier})
            .then((data) => {
                this.successSwal('Variable eliminada', '')
                this.fetchTestVariables(false)
            })
            .catch((data) => {
                Swal.fire({
                title: '',
                text: 'La variable no ha podido eliminarse',
                customClass:"swal-custom",
                showConfirmButton: false,
                icon: 'error',
                timer: 3000
                });
            })
        },
        async savePersonalization(){
            let vm = this
            this.reports[this.reportTab].personalizationData.colors = this.editingColors
            await store.dispatch('savePersonalization', {
                testId: this.testId,
                reportId: this.reports[this.reportTab].identifier,
                data: this.reports[this.reportTab].personalizationData
            })
            .then((data) => {
                const MonitorNew = this.$refs['Monitor-' + this.reportTab];
                for(let index in this.reports[this.reportTab].questions){
                    MonitorNew.generateChart(index)
                }
                this.closeColorEditor()
                this.successSwal(vm.$t('watmonitor_success_operation'), vm.$t('watmonitor_personalizated_save'))
            })
        },
        openColorEditor(){
            this.editingColors = this.reports[this.reportTab].personalizationData.colors
            this.showColorEditor = true
        },
        closeColorEditor(){
            this.showColorEditor = false
            this.editingColorIndex = -1
        },
        changeColor(color){
            this.editingColors[this.editingColorIndex] = color.hex
        },
        restorePersonalization(){
            this.editingColors = ["#8134ff","#FF1564","#FD7328","#361466","#895AD2","#96C800","#FD4D40","#f9c39f","#ede8f2","#669d98","#a81187","#8aefbb"]
        },
        successSwal(title, text){
            Swal.fire({
              title: title,
              text: text,
              customClass:"swal-custom",
              imageUrl: require('~/assets/img/WatMonitor/successIcon.svg'),
              showConfirmButton: false,
              timer: 3000
            });
        }
    },
    created(){
      this.saveTestAndReportIds()
      this.$router.replace({ query: undefined });
    },
    async mounted() {
      const canAccess = this.checkAccess()
      if(canAccess) this.getWatMonitorAllData()
    }
  }
  </script>
  
  <style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Lato:400,700');
  .swal-customWarning{
    border: 5px solid #F8BB86;
    border-radius: 0px;
    font-family: Lato;
    h2{
      margin-bottom: 0px;
      font-size:30px;
    }
    p{
      font-size: 17px;
    }
  }
  .swal-custom{
    border: 5px solid rgba(150, 200, 0, 0.18);
    border-radius: 0px;
    font-family: Lato;
    h2{
      margin-bottom: 0px;
      font-size:30px;
    }
    p{
      font-size: 17px;
    }
    .swal2-html-container{
      font-family: Bariol;
      color: #74818C;
    }
    button{
      &.swal2-confirm{
        background-color: #A2DE62!important;
      }
      &.swal2-cancel{
        background-color: #C1C1C1!important;
      }
    }
  }
 
  .WatMonitor{
    margin-bottom: 0px;
    &--Public{
      #main-content{
        margin-top:-80px!important;
        @media(max-width: 999px) {
          margin-top: -60px!important;
        }
      }
    }
    &.main-content{
      padding: 0px;
      @media(max-width: 768px) {
        padding-top: 60px!important;
      }
    }
  }
  .ColorModal{
    display: flex;
    flex-direction: column;
    gap: 10px;
    p{
        font-family: Lato;
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
        color:#96C800;
    }
    .ColorPicker{
        position: absolute;
        background: white;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
        border: 1px solid lightgray;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 30px;
        z-index: 1;
        top: 80px;
        height: 80%;
        .hu-color-picker{
            box-shadow: none;
        }
        &--Close{
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
    h1{
        margin-bottom: 10px;
        font-family: Bariol;
        font-size: 24px;
        font-weight: 700;
    }
    &--Color{
        display: flex;
        justify-content: space-between;
        gap: 40px;
    }
    &--Input{
        display: flex;
        gap: 8px;
        align-items: center;
        i{
            font-size: 13px;
            color: red;
            cursor: pointer;
        }
    }
    input{
        border: 1px solid lightgray;
        padding: 3px;
        border-radius: 3px;
    }
    span{
        width: 16px;
        height: 16px;
        border-radius: 3px;
    }
    img{
        cursor: pointer;
        transition: 0.2s;
        &:hover{
            transform: rotate(180deg);
        }
    }
  }
  </style>
  